<template>
	<figure id="logo">
		<img class="img-responsive" alt="Logo" :src="'https://cdn.finer.com.br/sistemas/logo_finer-comp-transp.png'">
	</figure>
</template>

<script>
	// import Logo from "@/assets/images/LOGO-FINER---PNG.png-box.png"
	export default {
		name: 'Logo',
		data() {
			return {
				// Logo: Logo,
			}
		},
	}
</script>

<style lang="scss">
	#logo{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 150px;
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>